import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const KofiButton = () => {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
        kofi
        }
      }
    }
  `);

    return (
        <a
            className="shadow-md text-white flex gap-2 p-2 font-bold text-xs md:text-sm rounded items-center h-10 bg-bmac mac-hover w-40 md:w-44"
            href={data.site.siteMetadata.kofi}
            rel="noopener"
            target="_blank"
            aria-label="Ko-fi"
        >
            <img
                loading="lazy"
                className="h-4"
                src="https://storage.ko-fi.com/cdn/cup-border.png"
                alt="Ko-fi donations"
            />
            Buy Me a Coffee
        </a>
    )
}

export { KofiButton };
