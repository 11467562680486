import * as React from "react";

const Google = () => {
    return <span className="font-semibold">
        <span style={{color: '#4285F4'}}>G</span>
        <span style={{color: '#EA4335'}}>o</span>
        <span style={{color: '#FBBC05'}}>o</span>
        <span style={{color: '#4285F4'}}>g</span>
        <span style={{color: '#34A853'}}>l</span>
        <span style={{color: '#EA4335'}}>e</span>
      </span>
}


export {Google};
