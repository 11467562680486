import React, {useEffect} from "react";
// import "prism-themes/themes/prism-vsc-dark-plus.css";
// import "prismjs/plugins/line-numbers/prism-line-numbers.min";
// import "prismjs/plugins/line-numbers/prism-line-numbers.css";
// @ts-ignore
import * as styles from "./CodeBlock.module.css";
import clsx from "clsx";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import light from 'react-syntax-highlighter/dist/cjs/styles/prism/vsc-dark-plus';

const getLanguageAbbrev = (langauge: string) => {
  switch (langauge) {
    case "typescript":
      return "TS";

    case "javascript":
      return "JS";
  }

  return langauge;
};

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

interface CodeBlockProps {
  title?: string;
  language?: string;
  children: string;
  hideLanguage?: boolean;
  removePadding?: boolean;
  pushLeft?: boolean;
  className?: string;
  style?: any;
  width?: number;
}

const CodeBlock = (props: CodeBlockProps) => {
  useEffect(() => {
    if (props.language) {
     // require(`prismjs/components/prism-${props.language}.min`);
    }

    // Prism.highlightAll();
  }, [props.language, props.children]);

  return (
    <div
      className={clsx(
        "rounded-xl font-mono overflow-x-auto block",
        !props.removePadding && "mb-8",
        props.pushLeft ? "ml-auto" : "mx-auto",
        props.className != null && props.className
      )}
      style={props.style}
    >
      {!props.hideLanguage && (
        <div className="relative">
          <div
            className={clsx(
              "right-4 absolute bg-blue-500 rounded-b-sm",
              props.title && "top-9"
            )}
            style={{ zIndex: 9 }}
          >
            <div className="rounded-b-sm bg-secondary px-2 text-xs text-white">
              <span className={styles.toolbarItem}>
                {capitalizeFirstLetter(getLanguageAbbrev(props.language || ""))}
              </span>
            </div>
          </div>
        </div>
      )}
      {(props.title || props.showTitleBar) && (
        <div className="bg-gray-900 text-gray-300 h-9 rounded-t-xl">
          <div className="flex flex-row gap-5 rounded-xl h-9 items-center">
            <div className="flex gap-1 self-center ml-3">
              <div className={"w-3 h-3 rounded-full bg-red-500"} />
              <div className={"w-3 h-3 rounded-full bg-yellow-500"} />
              <div className={"w-3 h-3 rounded-full bg-green-500"} />
            </div>
            <div className={clsx(styles.title)}>{props.title}</div>
          </div>
        </div>
      )}
      <pre>
        <SyntaxHighlighter showLineNumbers language={props.language} style={light} PreTag="div" className={clsx(
            "line-numbers rounded-b-xl",
            !props.title && !props.showTitleBar && "rounded-xl",
            props.title && !props.showTitleBar && styles.titleBar
        )}>
        {props.children.toString()}
      </SyntaxHighlighter>
      </pre>
    </div>
  );
};

export { CodeBlock };
