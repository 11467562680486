import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const PatreonButton = () => {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
        patreon
        }
      }
    }
  `);

    return (
        <a
            className="shadow-md text-white flex gap-2 px-2 font-bold text-xs md:text-sm rounded items-center bg-patreon hover:bg-red-500 h-10 w-40 md:w-44"
            href={data.site.siteMetadata.patreon}
            rel="noopener"
            target="_blank"
            aria-label="Patron"
        >
            <svg
                className="h-4"
                viewBox="0 0 569 546"
                xmlns="http://www.w3.org/2000/svg"
                style={{color: "white"}}
            >
                <g>
                    <circle
                        cx="362.589996"
                        cy="204.589996"
                        data-fill="1"
                        fill="white"
                        id="Oval"
                        r="204.589996"
                    />
                    <rect
                        data-fill="2"
                        height="545.799988"
                        id="Rectangle"
                        width="100"
                        x="0"
                        y="0"
                    />
                </g>
            </svg>
            Become a Patron
        </a>
    )
}

export { PatreonButton };
