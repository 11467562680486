import axios from "axios";
import {useEffect} from "react";

const useIncrementArticleViewCount = (slug) => {
    useEffect(() => {
        axios.patch(process.env.GATSBY_INCREMENT_ARTICLE_VIEWS_ENDPOINT + "", {
            "slug": slug
        });
    }, [slug]);

    return {loading: false, success: true};
}

export { useIncrementArticleViewCount };
