import clsx from "clsx";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { BlogTag } from "../components/BlogTag";
import { Breadcrumb } from "../components/Breadcrumb";
import { Google } from "../components/Google";
import { Newsletter } from "../components/Newsletter";
import { Period } from "../components/Period";
import { Seo } from "../components/Seo";
import { buildArticleRoute, buildCategoryRoute, routes } from "../util/routes";
// @ts-ignore
import * as articleStyles from "./Article.module.css";
import ReactMarkdown from "react-markdown";
import { addDecimalToThousands, slugify } from "../util/stringUtil";
import rehypeRaw from "rehype-raw";
import { convertDate } from "../util/time";
import { PatreonButton } from "../components/PatreonButton";
import { KofiButton } from "../components/KofiButton";
import { useGetArticleViews } from "../hooks/useGetArticleViews";
import { useIncrementArticleViewCount } from "../hooks/useIncrementArticleViewCount";
import { CodeBlock } from "../components/CodeBlock";
import { Comments } from "../components/Comments";
import Image from "@graphcms/react-image";

const getDivWithProps = (src) => {
  if (typeof window === `undefined`) {
    return {
      width: 0,
      height: 0,
    };
  }

  const div = document.getElementById(src);

  if (div) {
    return {
      width: div.dataset.width,
      height: div.dataset.height,
    };
  }

  return {
    width: 0,
    height: 0,
  };
};

const Toc = (props) => {
  if (!props.headings || props.headings.length === 0) {
    return <></>;
  }

  const [tocHTML, setTocHTML] = useState(<></>);

  const generateTOC = (headers, prevDepth, index) => {
    if (index === headers.length) {
      return "</ul>";
    }

    const header = headers[index];
    let content = "";

    if (header.depth > prevDepth) {
      content += "<ul>";
    }

    if (header.depth < prevDepth) {
      const numOfClosing = prevDepth - header.depth;
      content += "</ul>".repeat(numOfClosing);
    }

    content += `<li class="border-b-0"><a href="#${slugify(header.content)}">${
      header.content
    }</a></li>`;

    return content + generateTOC(headers, header.depth, index + 1);
  };

  useEffect(() => {
    const allPre = document.querySelectorAll("pre");

    for (let i = 0; i < allPre.length; ++i) {
      const node = allPre[i];

      var d = document.createElement("div");
      d.innerHTML = node.innerHTML;

      node.parentNode.replaceChild(d, node);
    }

    const layers = [];

    props.headings.map((heading) => {
      const matchedHashes = heading.match(/^#+/);
      const depth = matchedHashes[0].split("").length;
      const content = heading.replace(/^#+/, "");

      layers.push({ depth: depth, content: content.trim() });
    });

    setTocHTML(generateTOC(layers, 0, 0));
  }, [props.headings]);

  return (
    <nav
      className="table-of-contents"
      dangerouslySetInnerHTML={{ __html: tocHTML }}
    />
  );
};

const SingleArticle = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    {
      graphCmsAsset(
        handle: { eq: "oxKRhCGKSk2MFJZNIVTE" }
        stage: { eq: PUBLISHED }
      ) {
        id
        gatsbyImageData(quality: 70, placeholder: BLURRED, width: 208)
        handle
      }
    }
  `);

  let article = pageContext.article;
  const [codeWidth, setCodeWidth] = useState(700);

  const { loading, views } = useGetArticleViews(article.slug);
  useIncrementArticleViewCount(article.slug);

  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const regXHeader = /#{1,6}\s.+/g;

    setHeadings(article.content.match(regXHeader));
  }, [article]);

  return (
    <>
      <Seo
        blogPage
        title={article.title}
        description={article.excerpt}
        image={article.contentImage.image.url}
        datePublished={new Date(article.created).toISOString()}
        dateModified={new Date(article.updated).toISOString()}
        type={{
          type: "article",
          child: {
            "article:section": article.category.label,
            "article:published_time": new Date(article.created).toISOString(),
            "article:modified_time": new Date(article.updated).toISOString(),
          },
        }}
      />
      <Breadcrumb
        breadcrumbs={[
          {
            label: "Blog",
            url: routes.blog,
          },
        ]}
        activeLabel="Article"
      />

      {/* TODO USE FIGURE FOR IMAGES and figcaption */}
      <article className="max-w-3xl mx-auto pb-10 px-5">
        <header
          className={clsx(
            "flex flex-col items-center justify-center dark:text-gray-300 pt-5 pb-5 mt-5 rounded"
          )}
          style={{ minHeight: "15rem" }}
        >
          <div className="max-w-3xl">
            <Link
              to={buildCategoryRoute(article.category.label)}
              className="text-md font-semibold tracking-wider text-blue-500 block"
            >
              {article.category.label}
            </Link>

            <h1
              className="text-3xl md:text-5xl md:leading-tight font-extrabold text-left dark:text-gray-300 text-black mb-3 md:mb-5"
              style={{ lineHeight: "1.15" }}
            >
              {article.title}
            </h1>

            <p
              className="dark:text-gray-300 mx-auto text-left text-gray-600 text-lg md:text-lg md:mb-5"
              style={{ lineHeight: "1.4em" }}
            >
              {article.excerpt}
            </p>

            <div className="flex items-start my-3">
              <div className="relative">
                <GatsbyImage
                  loading="lazy"
                  className="hidden md:block mx-auto object-cover rounded-full h-10 w-10"
                  alt="Gregory Gaines"
                  image={data.graphCmsAsset.gatsbyImageData}
                />
              </div>
              <div className="flex flex-col items-start justify-center md:ml-2">
                <Link
                  to={routes.aboutMe}
                  className="text-sm flex items-center font-semibold hover:text-blue-500"
                >
                  Written by Gregory Gaines
                </Link>
                <div className="text-sm flex gap-2 md:gap-3">
                  <time className="inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-0.5 md:mr-1 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {convertDate(article.created)}
                  </time>

                  <div className="inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-0.5 md:mr-1 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    {article.readTimeMinutes} min read
                  </div>

                  <div className="inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-0.5 md:mr-1 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                    {addDecimalToThousands(views)} views
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-7 space-x-2">
              {article.tags.map((tag) => {
                return <BlogTag label={tag.label} key={tag.label} />;
              })}
            </div>
          </div>
        </header>
        <div
          id="article"
          className={clsx("mx-auto text-md pb-0 mt-4", articleStyles.article)}
        >
          <div className="text-center mb-6">
            <GatsbyImage
              loading="lazy"
              className="h-70 bg-center rounded-lg mb-2"
              alt={article.contentImage.image.alt}
              image={article.contentImage.image.gatsbyImageData}
            />

            {article.contentImage.attributionURL && (
              <span className="text-sm text-gray-600 dark:text-gray-300">
                Photo by{" "}
                <a
                  className="font-bold"
                  href={article.contentImage.attributionURL}
                  rel="noreferrer"
                >
                  {article.contentImage.attributionTitle}
                </a>{" "}
                on{" "}
                <a
                  className="font-bold"
                  href={article.contentImage.sourceURL}
                  rel="noreferrer"
                >
                  {article.contentImage.source}
                </a>
              </span>
            )}
          </div>
          <div className="mx-auto max-w-3xl dark:text-gray-300">
            <div className="border rounded p-5 my-10">
              <h2 className="text-2xl mt-0" style={{ marginTop: "0" }}>
                Table of Contents
              </h2>
              <Toc headings={headings} className="table-of-contents" />
            </div>
            <ReactMarkdown
              skipHtml={false}
              rehypePlugins={[rehypeRaw]}
              allowElement={(props) => {
                return true;
              }}
              /*   text-gray-800 dark:text-breen-500 */
              components={{
                img: (props) => {
                  // Get div with props
                  const dim = getDivWithProps(props.src);

                  if (props.src.includes(".com")) {
                    return <img src={props.src} />
                  }

                  return (
                    <Image
                      alt={props.alt}
                      outerWrapperClassName={{objectFit: "contain", height: "200px"}}
                      image={{
                        handle: props.src,
                        width: dim.width,
                        height: dim.height,
                      }}
                      withWebp
                    />
                  );
                },
                h1: (props) => {
                  return (
                    <h2
                      id={slugify(props.children.toString())}
                      className={clsx(
                        "text-3xl font-bold text-gray-800 dark:text-white",
                        props.className
                      )}
                      style={{ fontSize: "2rem" }}
                    >
                      {props.children}
                    </h2>
                  );
                },
                h2: (props) => {
                  return (
                    <h3
                      id={slugify(props.children.toString())}
                      className={clsx(
                        "text-3xl font-semibold text-gray-800 dark:text-white",
                        props.className
                      )}
                    >
                      {props.children}
                    </h3>
                  );
                },
                h3: (props) => {
                  return (
                    <h4
                      id={slugify(props.children.toString())}
                      className={clsx(
                        "text-2xl text-gray-800 dark:text-white border-b pb-2",
                        props.className
                      )}
                    >
                      {props.children}
                    </h4>
                  );
                },
                h4: (props) => {
                  return (
                    <h5
                      id={slugify(props.children.toString())}
                      className={clsx(
                        "text-xl text-gray-800 dark:text-white border-b pb-2",
                        props.className
                      )}
                    >
                      {props.children}
                    </h5>
                  );
                },
                ul: (props) => {
                  return (
                    <ul className="list-disc list-inside md:ml-4 mb-8">
                      {props.children}
                    </ul>
                  );
                },
                ol: (props) => {
                  // TODO gET BLOCK QUOTE CODE WORKING
                  return (
                    <ol className="list-decimal list-inside md:ml-4 mb-8">
                      {props.children}
                    </ol>
                  );
                },
                li: (props) => {
                  return (
                    <li key={props.children} className="mb-0">
                      {props.children}
                    </li>
                  );
                },
                strong: (props) => {
                  return (
                    <strong className="text-gray-800 dark:text-white">
                      {props.children}
                    </strong>
                  );
                },
                p: (props) => {
                  const text = props.children[0] + "";

                  if (text.startsWith("Warning:")) {
                    return (
                      <div className="rounded-r-xl border-l-8 p-5 border border-yellow-400 dark:bg-gray-900 my-5">
                        <p
                          className="dark:text-gray-300 text-yellow-700 mb-0 pb-0"
                          style={{ marginBottom: "0px" }}
                        >
                          {props.children[0].substring(8)}
                        </p>
                      </div>
                    );
                  }

                    if (text.startsWith(":::note")) {
                        return (
                            <div className="rounded-r-xl rounded-l-xl border-l-8 p-5 border border-blue-400 dark:bg-gray-900 my-5">
                                <p
                                    className="dark:text-gray-300 mb-0 pb-0"
                                    style={{ marginBottom: "0px" }}
                                >
                                    {props.children[0].substring(8)}
                                </p>
                            </div>
                        );
                    }

                  return (
                    <p className="dark:text-gray-300 text-gray-600">
                      {props.children}
                    </p>
                  );
                },
                code: (props) => {
                  if (props.inline) {
                    return (
                      <code className="text-sm px-1 py-0.5 bg-gray-100 text-blue-600 font-medium dark:text-white dark:bg-gray-900 rounded">
                        {props.children}
                      </code>
                    );
                  }

                  const match = /language-(\w+)/.exec(props.className || "");

                  let language;

                  if (match?.length > 0) {
                    language = match[1];
                  }

                  let attributes = {
                    title: null,
                  };

                  // Get attributes
                  if (props.children[0].charAt(0) === "{") {
                    const start = 0;
                    const end = props.children[0].indexOf("}") + 1;

                    const parsed = props.children[0].substring(start, end);

                    attributes = JSON.parse(parsed);

                    // Remove attributes
                    props.children[0].substring(start, end);

                    props.children[0] = props.children[0].substring(
                      props.children[0].indexOf("\n") + 1
                    );
                  }

                  props.children[0] = props.children[0].slice(0, -1);

                  return (
                    <div>
                      <CodeBlock
                        title={attributes.title}
                        language={language}
                        width={codeWidth}
                      >
                        {props.children}
                      </CodeBlock>
                    </div>
                  );
                },
                a: (props) => {
                  if (props.href === "/support/") {
                    return <Link to="/support/">{props.children}</Link>;
                  }

                  if (props.href.charAt(0) === "/") {
                    return <Link to={props.href}>{props.children}</Link>;
                  }

                  if (
                    props.href ===
                    "https://gregorygaines.substack.com/subscribe"
                  ) {
                    return (
                      <a
                        href="https://gregorygaines.substack.com/subscribe"
                        target="_blank"
                      >
                        {props.children}
                      </a>
                    );
                  }

                  return (
                    <a href={props.href} rel="noreferrer" target="_blank">
                      {props.children}
                    </a>
                  );
                },
              }}
            >
              {article.content}
            </ReactMarkdown>
          </div>
        </div>
      </article>

      <nav className="max-w-3xl mx-auto flex dark:text-gray-300 gap-5 flex-wrap md:flex-nowrap px-5">
        {pageContext.prevArticle && (
          <Link
            to={buildArticleRoute(pageContext.prevArticle.slug)}
            className={clsx(
              "border-l-4  border-gray-300 border p-5 w-full rounded hover:border-blue-500 hover:shadow-lg"
            )}
          >
            <p className="text-lg font-bold">Previous</p>
            <p className="text-left text-sm font-semibold mt-2 leading-tight">
              {pageContext.prevArticle.title}
            </p>
          </Link>
        )}
        {pageContext.nextArticle && (
          <Link
            to={buildArticleRoute(pageContext.nextArticle.slug)}
            className={clsx(
              "border-r-4 border-gray-300 border md:text-right p-5 w-full rounded hover:border-green-500 hover:shadow-lg"
            )}
          >
            <p className="text-lg justify-end font-bold text-right">Next</p>

            <p className="text-right mt-2 text-sm font-semibold leading-tight">
              {pageContext.nextArticle?.title}
            </p>
          </Link>
        )}
      </nav>

      <section className="max-w-3xl px-5 mx-auto">
        <div className="mx-auto rounded my-10 dark:bg-gray-900 border-t-8 border-green-500">
          <div className="mx-auto p-5 md:p-10 shadow-lg rounded flex flex-col lg:flex-row">
            <div className="">
              <h2 className="text-3xl font-extrabold leading-tight dark:text-gray-300 mb-2">
                About the author
                <Period />
              </h2>

              <p className="mt-4 lg:max-w-md dark:text-gray-300">
                I'm{" "}
                <Link
                  to={routes.aboutMe}
                  className="text-blue-500 dark:text-green-500 underline font-medium"
                >
                  Gregory Gaines
                </Link>
                , a software engineer that loves blogging, studying computer
                science, and reverse engineering.
              </p>
              <p className="mt-4 dark:text-gray-300">
                I'm currently employed at <Google />;{" "}
                <span className="underline font-medium">
                  all opinions are my own.
                </span>
              </p>

              <span className="flex gap-2 mt-5">
                <KofiButton />
                <PatreonButton />
              </span>
            </div>

            <div className="mt-8 lg:mt-0 lg:w-1/2">
              <div className="flex items-center justify-center lg:justify-end">
                <div className="max-w-lg">
                  <GatsbyImage
                    loading="lazy"
                    imgClassName="object-cover object-center w-full h-64 rounded-md shadow"
                    alt="Gregory Gaines"
                    image={data.graphCmsAsset.gatsbyImageData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-10 mx-auto max-w-3xl" />

      <section className="max-w-3xl mx-auto px-5">
        <h2 className="text-3xl font-extrabold leading-tight dark:text-gray-300 mt-10 mb-10">
          You may also like
          <Period />
        </h2>
        <nav className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 gap-5 mx-auto mb-10">
          {pageContext.relatedPosts.map((article) => {
            return (
              <div key={article.title}>
                <div className="relative mb-2">
                  <Link to={buildArticleRoute(article.slug)}>
                    {article.contentImage.image?.gatsbyImageData && (
                      <GatsbyImage
                        loading="lazy"
                        className="object-center h-40 w-full rounded hover:opacity-75"
                        alt={article.contentImage.image?.alt ?? ""}
                        image={article.contentImage.image.gatsbyImageData}
                      />
                    )}
                  </Link>
                  <span className="px-2 py-1 text-white bg-gray-900 dark:text-gray-300 dark:bg-gray-800 text-xs rounded absolute right-1 bottom-1 bg-opacity-60">
                    {article.readTimeMinutes} min read
                  </span>
                </div>
                <Link
                  to={buildCategoryRoute(article.category.label)}
                  className="text-sm font-semibold text-blue-600"
                >
                  {article.category.label}
                </Link>
                <h3 className="text-md font-bold dark:text-gray-300">
                  <Link
                    className="hover:text-green-500"
                    to={buildArticleRoute(article.slug)}
                  >
                    {article.title}
                  </Link>
                </h3>
              </div>
            );
          })}
        </nav>
      </section>

      <section className="max-w-3xl mx-auto px-5 mb-10">
        <h2 className="text-3xl font-extrabold leading-tight dark:text-gray-300">
          Comments
          <Period />
        </h2>
        <Comments slug={article.slug} />
      </section>

      <Newsletter location={location} />
    </>
  );
};

export default SingleArticle;
